import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col, Container } from "reactstrap"
import BackgroundImage from "../components/backgroundImage"
import { Link } from "gatsby"

function AboutUs() {
  return (
    <Layout>
      <SEO
        title="About-Us"
        description="First Rate Merchant is one of the best service provider in whole USA. It has has stayed true to its original mission bring educated buyers and sellers together at the time they are ready to do business"
      />
      <div>
        <Container className="p-md-5 p-xs-0">
          <Row className="about-us-wrapper">
            <Col xs={12} md={5} className="p-5 d-none d-md-block">
              <BackgroundImage
                src="about-us-bg.jpeg"
                style={{ width: "100", height: "100%" }}
              />
            </Col>
            <Col
              xs={12}
              md={7}
              className="p-md-5 p-3 d-flex flex-column justify-content-center"
            >
              <h3 className="text-color-secondary display-4">About Us</h3>
              <p className="mt-4 text-justify">
                <span className="lead">First Rate Merchant</span> is your #1
                Source for finding the correct nearby contractual workers to
                complete your home improvement, maintenance and repair projects.
                There is no compelling reason to invest hours calling several
                contract workers from the nearby telephone directory or scanning
                for organizations on the web. Our process saves you time and
                money.
              </p>
              <p className="text-justify">
                To begin,{" "}
                <Link to="/all-services" className="text-color-secondary">
                  <b>click here</b>
                </Link>{" "}
                and pick the service your need to get quotes from local
                contractors.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default AboutUs
